import { render, staticRenderFns } from "./Fees.vue?vue&type=template&id=0ed02983&scoped=true&"
import script from "./Fees.vue?vue&type=script&lang=js&"
export * from "./Fees.vue?vue&type=script&lang=js&"
import style0 from "./Fees.vue?vue&type=style&index=0&id=0ed02983&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed02983",
  null
  
)

export default component.exports