<template>
    <b-tabs v-model="tabIndex">      
      <b-tab @click="clickTab">
        <template #title >
          <div> Fees </div>
        </template>
        <ListFees @set-tab-index="setTabIndex"/>                        
      </b-tab>
        
      <b-tab  @click="clickTab">
        <template #title >
            <div :class="can('fivesclub_profit_save_fee') ? '' : 'd-none'">
              Crear
            </div>
          </template>
        <FeesCreate @set-tab-index="setTabIndex"/>            
      </b-tab>  

      <b-tab>
        <template #title >
          <div :class="selectedFees ? '' : 'd-none'">
            Actualizar
          </div>
        </template>
        <FeesEdit @set-tab-index="setTabIndex"/>              
      </b-tab>  

    </b-tabs> 
</template>

<script>
import { mapState,mapMutations } from "vuex"
import ListFees from '@/modules/fivesClub/components/profit/fees/ListFees'
import FeesCreate from '@/modules/fivesClub/components/profit/fees/FeesCreate'
import FeesEdit from '@/modules/fivesClub/components/profit/fees/FeesEdit'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],  

  components:{
    ListFees,
    FeesCreate,
    FeesEdit
  },
 created() {
    
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState('fivesClubProfit',['selectedFees']),
   
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setSelectedFees']),
    setTabIndex( index ){      
      this.tabIndex = index    
    },
    clickTab(){
     this.setSelectedFees(null)
    }
  },
};
</script>
<style scoped>
.nav-item.active.tab-active-class {
  background-color: red;
}
</style>