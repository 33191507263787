<template>
  <b-card>
    <Fees/>   
  </b-card>
</template>

<script>
import { mapActions,mapState,mapMutations  } from "vuex";
import Fees from '@/modules/fivesClub/components/profit/fees/Fees.vue'
export default {
	async mounted() {
		if( this.rentalPoolFilter.length !=0 ) return false
			let rentalPool= await this.fetchGetRentalPoolFilter();
      		this.setRentalPoolFilter(rentalPool)
    },
	components:{ Fees },
	computed:{
		...mapState("fivesClubProfit", ['rentalPoolFilter']),
	},
	methods: {
		...mapMutations('fivesClubProfit', ['setRentalPoolFilter']),
		...mapActions('fivesClubProfit', ["fetchGetRentalPoolFilter"]),
	},
};
</script>
