
<template>
  <div>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      @change-per-page="changePerPage"
      @look-per-filter="filteredSearch"
    />
   <br>

   <TableFees
      v-if="!isLoadingFees"
      :feesData="profitDataList"
      :tableColumns="tableColumns"
      :sortBy="sortBy"
      :isSortDirDesc="isSortDirDesc"
      :isEmpty="isEmpty"
      :isLoading="isDowloadingExcel"
      fixed
      responsive
      small
      style="overflow:auto"
      @change-per-page="changePerPage"
      @set-tab-index="setTabIndex"
    />

    <div class="isCentered isSpinner" v-else>
      <center>
        <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
        Cargando distribución de rendimiento
      </center>
    </div>

    <PaginationTable
      :currentPage="currentPage"
      :totalRows="filteredFees.length"
      :perPage="perPage"
      @change-page="changePage"
    />

  </div>
</template>

<script>
import { formatDateOnly } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Filters from '@/modules/fivesClub/components/profit/fees/Filters'
import TableFees from '@/modules/fivesClub/components/profit/fees/TableFees'
import PaginationTable from '@/modules/fivesClub/components/profit/fees/PaginationTable'
import { monthList } from "@/data/data";

export default {
  components:{
    TableFees,
    PaginationTable,
    Filters,
  },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      perPageOptions: [50, 100, 150, 200, 250, 500, 1000],
      searchQuery: '',
      sortBy: 'name',
      isSortDirDesc: true,
      refListTable : null,
      statusFilter: null,
      isDowloadingExcel:false,
      tableColumns: [
        { key: "rentalPoolName", label: "Pool de Renta", class: "text-center" },
        { key: "fees", label: "Fees", class: "text-center", formatter: value => {
          return this.feesType?.find(item => item.id == value)?.name
        }},
        { key: "valueTypeName", stickyColumn: true, label: "Tipo de valor", class: "text-center" },
        { key: "estimateTypeName", label: "Tipo de Estimación", class: "text-center" },
        { key: "value", label: "Valor", class: "text-center", formatter: (value, key, item) => {
          return item.valueType == 1 ? `${parseFloat(value).toFixed(2)}%` : item.valueType == 2 ? `$${value}` : value
        }},
        { key: "year", label: "Año", class: "text-center" },
        { key: "month", label: "Mes", class: "text-center", formatter: (value) => { return value ? monthList.find( m => m.value === value ).text : '--' } },
        
        { key: "isActive", label: "¿Está activo?", class: "text-center", formatter: value => {
          return value ? 'SI' : 'NO'
        }},
        { key: 'actions', label: 'Acciones' },

      ],
      isLoadingFees: false,

      isEmpty:false
    };
  },
  async mounted() {
    const feesList = await this.fetchFeeSettings()
    this.setFees(feesList)
    this.isEmpty=true
    if(this.feesType.length!=0) return false
    let feesTypeList= await this.fetchfeesType();
    this.setFeesType(feesTypeList)
  },
  computed: {
    ...mapState("fivesClubProfit", ['fees','feesType']),
    ...mapGetters('fivesClubProfit',['filteredFees']),
    profitDataList(){
      if (this.filteredFees?.length) return this.filteredFees?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setFees','setFeesType']),
    ...mapActions('fivesClubProfit', ['fetchFeeSettings','fetchfeesType']),

    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    },

    async filteredSearch(payload){
      console.log(payload)
      this.isLoadingFees = true
      const feesList = await this.fetchFeeSettings( payload )
      this.setFees(feesList)
      this.isEmpty=false
      this.isLoadingFees = false
    },
  },

};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>
