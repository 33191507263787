<template>
  <div>
    <b-table
      :items="feesData"
      sticky-header="600px"
      responsive
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      primary-key="id"
      :sort-by.sync="isSortBy"
      show-empty
      empty-text="No hay registros"
      :sort-desc.sync="isSortDir"
      class="position-relative mb-0"
      :busy.sync="isSavingProfit"
    >
      <template #cell()="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(actions)="row">
        <div>
          <b-button
            size="sm"
            @click="setFeeToEdit(row.item)"
            variant="primary"
          >
            <feather-icon icon="Edit2Icon" size="12" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils";

export default {
  mixins: [utils],
  props: {
    feesData: {
      type: Array,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
    isLoadingCosts: {
      type: Boolean,
      required: false,
    },
    isEmpty: {
      type: Boolean,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      isSavingProfit: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapMutations('fivesClubProfit', ['setSelectedFees']),

    setFeeToEdit(data) {
      this.$emit('set-tab-index', 2)
      this.setSelectedFees(data)
    },
  },
};
</script>

<style lang="scss" >
.display-6 {
  font-size: 13px;
}
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }
  .buttonInfo {
    display: table-cell;
  }
  .isCentered {
    align-content: center;
    align-items: center;
  }
  .isSpinner {
    padding: 2rem;
  }
}
</style>
